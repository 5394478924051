.open_search {
  @include susy-breakpoint($breakpoint-large, $susy-large) {
    @include line-height(1.5);
    @include line-width(1.5);
    @include absolute-top(.75);
  }
  span {
    @include line-height(1.5);
    @include line-width(1.5);
  }
  &:after {
    content: '\f002';
    display: block;
    position: absolute;
    @include line-height(1.5);
    @include line-width(1.5);
    color: palette(grey,base);
    text-align: right;
  }
}

.close_main_nav_search {
  display: none;
  border: none;
  outline: none;
  background-color: transparent;
  margin: 0;
  padding: 0;

  @at-root .site__header.searchbar_active .close_main_nav_search {
      display: block;
  }

  @include susy-breakpoint($breakpoint-large, $susy-large) {
    position: absolute;
    @include line-height(2);
    @include line-width(2);
    @include absolute-top(0);
    right: 0;
  }
  &:after {
    content: "\f00d";
    position: absolute;
    top:0;
    right: 0;
    @include line-height(2);
    @include line-width(2);
    font: normal normal normal 14px/1.8 FontAwesome;
    font-size: 1.5rem;
    text-rendering: auto;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: palette(grey,dark);
    z-index: 2;
  }
}

.open_search {
  display: none;
  @include susy-breakpoint($breakpoint-large, $susy-large) {
    display: block;
    position: absolute;
    right: 0;
    border: 0;
    outline: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  span {
    position: absolute;
    top:0;
    right: 0;
    bottom:0;
    left: 0;
    white-space: nowrap;
    text-indent: 200%;
    overflow: hidden;
  }
  &:after {
    top:0;
    right: 0;
    bottom:0;
    left: 0;
    font: normal normal normal 16px/1 FontAwesome;
    @include set-font-size-variant(large);
    @include custom-line-height(1.5);
    vertical-align: middle;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.main_nav_search {
  display: none;
  position: relative;
  @at-root .site__header.searchbar_active .main_nav_search {
    display: block;
  }
  @include susy-breakpoint($breakpoint-large, $susy-large) {
    position: absolute;
    @include absolute-top(.50);
    right: 0;
    bottom: 0;
    left: 0;
    input[type=search] {
      @include padding-left(2);
    }
    input[type=submit], button[type=submit] {
      left: 0;
    }
    &:before {
      left: 0;
      color: palette(grey,dark);
    }
  }
}

.widget_search {
  position: relative;
  input[type=submit], button[type=submit] {
    right: 0;
  }
  &:before {
    right: 0;
    color: palette(grey,bg);
  }
  .close_main_nav_search {
    display: none;
  }
}

.main_nav_search, .widget_search {
  background-color: transparent;
  input[type=search] {
    @include padding-right(2);
    font-weight: normal;
    font-family: $font-family;
  }
  input[type=submit], button[type=submit] {
    display: block;
    position: absolute;
    top:0;
    white-space: nowrap;
    text-indent: 200%;
    @include line-height(2);
    @include line-width(2);
    overflow: hidden;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
  }
  &:before {
    content: "\f002";
    position: absolute;
    top:0;
    @include line-height(2);
    @include line-width(2);
    font: normal normal normal 14px/1.8 FontAwesome;
    font-size: 1.5rem;
    text-rendering: auto;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .screen-reader-text {
    display: none;
  }
}


.nieuwsbox {
  .search-form {
    .search-wrap {
      display: block;
      position: relative;
      &:after {
        content: '\f0e0';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: auto;
        margin-top: auto;
        margin-bottom: auto;
        font-family: FontAwesome;
        @include custom-line-height(1);
        @include line-width(.5);
        @include line-height(1);
        @include margin-right(1);
        color: palette(green,base);
        text-align: right;
        vertical-align: middle;
        font-size: 1rem;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-decoration: none;
        border: none;
        @include susy-breakpoint($breakpoint-large, $susy-large) {
        }
      }
    }
    input[type=submit], button[type=submit] {
      background-color: palette(grey,bgwidgettitle);
      //@include padding-top(.25);
      //@include padding-bottom(.25);
      @include custom-line-height(.5);
      @include line-height(1.5);
    }
  }
}