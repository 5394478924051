///////////////////////////////////////////////////////////////////////
// At a minimum every palette defines a base colour,
// and then optionally adds tones use the following naming pattern:
//
// - x-dark
// - dark *
// - mid-dark
// - base (default)
// - mid-light
// - light *
// - x-light
//
//
// *If we have fewer than seven tones, we’ll define light and dark first to allow
// for adding intermediate tones at a later date.
//
// Use the palette function
//
// @link http://erskinedesign.com/blog/friendlier-colour-names-sass-maps/
//

$color-text-default: #333;
$palettes: (

    grey: (
        dark: darken(rgb(128, 128, 128), 10%),
        base: rgba(245, 245, 245, 1),
        light: rgba(128, 128, 128, .3),
        navs: rgba(99, 99, 99, 1),
        navborders: rgba(70, 70, 70, .3),
        bg: #a0ada3,
        bgwidgettitle: rgba(220, 220, 220, 1),
        bgwidget: rgba(240, 240, 240, 1),
        bgwidgetdate: rgba(210, 210, 210, 1),
        stroke: rgba(203, 203, 203, 1),
        postcounts: rgba(230, 230, 230, 1),
        text: rgb(51, 51, 51),
    ),
    white: (
        base: #fff,
        light: rgba(255, 255, 255, 0.8),
        lighter: rgba(255, 255, 255, .2),
    ),
    blue: (
        base: rgba(16, 45, 74, 1),
        basetrans: rgba(16, 45, 74, 0),
        light: rgba(16, 45, 74, 0.8),
    ),
    green: (
        base: rgb(160, 173, 163),
        light: rgba(119, 184, 41, 0.8),
        lighter: rgba(119, 184, 41, 0.95),
    )


);

///////////////////////////////////
// Access the colors in the $palettes map
// Example: color: palette(purple); color: palette(purple, light);
//
@function palette($palette, $tone: 'base') {
    @return map-get(map-get($palettes, $palette), $tone);
}
