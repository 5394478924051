/*
Theme Name: ibpix
Author: BASE
Author URI: http://ibpix.com
Description: them development by ibpix
Version: 0.1
Text Domain: ibpix

Use it to make something cool, have fun, and share what you've learned with others.
*/
/* ==========================================================================
    Base file, all includes, defines & custom mixins should be added here
    ========================================================================= */
// Framework includes
@import "susy";
@import "breakpoint";
@import 'modular-scale';
@import "../../../node_modules/vertical-rhythms-without-compass/vertical-rhythms";
// Vendor includes
@import "vendor/normalize";
@import "font-awesome/font-awesome";

@import "organisms/fonts";

// Utilities
@import "utilities/mixins";
@import "utilities/custom_rhythm_mixins";
@import "utilities/variables";
@import "utilities/palettes";
@import "utilities/grid";
@import "utilities/typography";

@import "vendor/flickity";

// Atoms
@import "atoms/html";
@import "atoms/body";
@import "atoms/buttons";
@import "atoms/headings";
@import "atoms/text-selection";
@import "atoms/links";
@import "atoms/lists";
@import "atoms/inputs";
@import "atoms/labels";
@import "atoms/hr";
// Molecules
@import "molecules/nav";
@import "molecules/content-blocks";
@import "molecules/home_slider";
@import "molecules/searchforms";

// Organisms
@import "organisms/footer";
@import "organisms/header";
@import "organisms/article";
// Templates
@import "templates/page";
@import "templates/sfeer";