.homeslides {
  display: block;
  position: relative;
  @include margin-bottom(2);
  //overflow: hidden;

  .slides {
    position: relative;

    .slides-cell {
      display: block;
      position: relative;
      width: 100%;
      @include susy-breakpoint($breakpoint-large, $susy-large) {

      }
      overflow: hidden;
      .image {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        //@include flexEmbed(3, 1);
        img {
          @include susy-breakpoint($breakpoint-large, $susy-large) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover; // fill image inside specified size whitout pulling out of proportion
            object-position: top left;
          }

        }
      }

      .content-position {
        position: relative;

        .content-wrap {
          //margin-left: auto;
          //margin-right: auto;
          @include container();
          @include clearfix();
          @include susy-breakpoint($breakpoint-large, $susy-large) {
            //width: 100%;
            @include container();
          }
          .content {
            display: block;
            background-color: palette(green, lighter);

            padding-left: span(1);
            padding-right: span(1);
            @include padding-top(2);
            @include padding-bottom(4);

            text-align: center;

            @include susy-breakpoint($breakpoint-large, $susy-large) {
              background-color: transparent;
              padding: 0;
              text-align: left;
              @include padding-bottom(2);
            }
            a, p, h1, h2, h3, h4, h5, h6, ul, li {
              color: palette(white, base);
            }
            h1, h2 {
              @include set-font-size-variant(xlarge);
              @include margin-bottom(.5);
              line-height: 2rem;
              @include susy-breakpoint($breakpoint-large, $susy-large) {
                @include rem('font-size', ms(8));
                line-height: 3rem;
              }
            }
            p {
              @include margin-top(.5);
              @include margin-bottom(2);
              @include set-font-size-variant(medium);
              @include susy-breakpoint($breakpoint-large, $susy-large) {
                @include set-font-size-variant(large);
              }
            }
            @include susy-breakpoint($breakpoint-large, $susy-large) {
              @include span(6 inside no-gutters last);
              @include rhythm-padding(3);
              @include padding-right(2);
              @include padding-left(2);
              @include margin-bottom(3);
              transition: margin-right 0s;
              margin-right: -100%;

              @at-root .homeslides .slides .slides-cell.is-selected.animate .content-position .content-wrap .content {
                transition: margin-right 1.2s;
                @include margin-right(0);

              }

            }
          }
        }
      }

    }
  }

  @include susy-breakpoint($breakpoint-large, $susy-large) {

    .image_mask-position {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      .image_mask-wrap {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        @include container();
        .image_mask {
          display: block;
          position: absolute;
          top: 0px;
          right: 0;
          bottom: 0;
          background-color: white;
          width: 50vw;
          margin-right: -50vw;
          //opacity: .5;
        }
        .image_mask-corner-wrap {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 15px;
          height: 15px;
          overflow: hidden;
          .image_mask-corner {
            position: absolute;
            width: 70px;
            height: 70px;
            right: -20px;
            bottom: -20px;
            border-radius: 50%;
            border: 20px solid white;
          }
        }

      }
    }

    .content-color {
      position: absolute;
      //display: none;
      @include span(6 inside no-gutters);
      top: 0;
      right: 0;

      transition: margin-right 0s;
      //transition: opacity .5s;
      //opacity: 0;
      margin-right: -100%;

      @include absolute-bottom(3);

      background-color: palette(green, base);
      @include custom-border-radius(0, 0, 0, 10);

      @at-root .slides-cell.is-selected.animate .content-color {
        transition: margin-right .6s;
        //transition: opacity .8s;
        //opacity: 100;
        margin-right: 0;
      }
    }

  }

  .godown {
    position: absolute;
    right: -50%;
    bottom: 0;
    left: -50%;
    @include line-width(4.5);
    @include line-height(4.5);
    @include margin-bottom(-2.25);
    margin-left: auto;
    margin-right: auto;
    background-color: palette(white, base);
    border-radius: 50%;
    text-align: center;
    //white-space: nowrap;
    //overflow: hidden;
    //text-indent: 200%;
    border: 12px solid palette(white, lighter);
    background-clip: content-box;
    cursor: pointer;
    a {
      color: palette(green, base);
    }
    i {
      @include margin-top(.60);
    }
  }


}