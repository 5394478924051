h1, h2, h3, h4, h5, h6 {

    font-family: $font-family-headings;
    color: palette(green, base);
}

h1 {
    //noinspection SassScssUnresolvedPlaceholderSelector
    @extend %t-xxxlarge;
}

h2 {
    //noinspection SassScssUnresolvedPlaceholderSelector
    @extend %t-large;
}

h3 {
    //noinspection SassScssUnresolvedPlaceholderSelector
    @extend %t-medium;
}

h4 {
    //noinspection SassScssUnresolvedPlaceholderSelector
    @extend %t-medium;
}

h5, h6 {
    //noinspection SassScssUnresolvedPlaceholderSelector
    @extend %t-medium;
}
