
a:hover {
    text-decoration: none;
}

a:visited {

}

a:active {

}

a:focus {

}
