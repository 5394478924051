/*! Flickity v2.0.2
http://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}

.flickity-slider {
  //position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  display: none;
  position: absolute;
  top: 50%;
  @include line-height(2);
  @include line-width(2);
  //width: 44px;
  //height: 44px;
  border: none;
  background: white;
  background: palette(blue, base);
  cursor: pointer;
  /* vertically center */
  transform: translateY(-50%);
  @include susy-breakpoint($breakpoint-large, $susy-large) {
    display: block;
  }
  @at-root .quote_slides-nav {
    .flickity-prev-next-button {
      margin-top: -12px;
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.flickity-prev-next-button:hover { background: palette(blue, light); }

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F;
}

.flickity-prev-next-button:active {
  opacity: 0.6;
}

.flickity-prev-next-button.previous {
  left: 0;
  @include custom-border-radius(0, 3, 3, 0);
}
.flickity-prev-next-button.next {
  right: 0;
  @include custom-border-radius(3, 0, 0, 3);
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 0;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 0;
}

.flickity-prev-next-button:disabled {
  opacity: 0.3;
  cursor: auto;
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.flickity-prev-next-button .arrow {
  fill: palette(white,base);
}

/* ---- page dots ---- */

.flickity-page-dots {
  @include container();
  position: absolute;
  width: 100%;
  right: -50%;
  bottom: 0;
  left: -50%;
  padding: 0;
  margin: 0 auto;
  list-style: none;
  text-align: center;
  line-height: 0;
  @include margin-bottom(2);
  @include padding-bottom(.75);
  @include susy-breakpoint($breakpoint-large, $susy-large) {
    @include container();
    @include margin-bottom(.5);
    @include padding-left(1);
    @include padding-right(1);
    text-align: right;
  }
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  line-height: 0;
  @include margin-left(.25);
  @include margin-right(.25);
  padding: 0;
  border-radius: 50%;
  cursor: pointer;

  width: 15px;
  height: 15px;
  opacity: 1;
  background: transparent;
  border: 2px solid white;

  text-indent: 200%;
  white-space: nowrap;
  overflow: hidden;

}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
  background: white;
}
