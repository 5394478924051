// Header element
// Full width on mobile, 2 columns wide on larger screens
.site__header {
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  background-color: palette(white);
  @include rhythm-height(3);
  @include clearfix();
  z-index: 10;
  @include default-shadow();
  //transition: background-color .4s;

  @include susy-breakpoint($breakpoint-large, $susy-large) {
    //position: relative;
    height: auto;
    @include padding-left(0);
    @include padding-right(0);
  }

  @at-root body {
    @include padding-top(3);
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      @include padding-top(3);
    }
  }

  &.scrolled {
    transition: background-color .8s;
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      transition: background-color .8s;

      background-color: transparent;
      box-shadow: none;

      @at-root .site__header.scrolled .inner {
        position: relative;
        &:after {
          content: ' ';
          position: absolute;
          top:0;
          @include absolute-left(-1);
          @include absolute-right(-4);
          @include line-height(3);
          background-color: palette(white ,base);
          min-width: 100vw;
          @include default-shadow();
          @include custom-border-radius(0,0,0,14);
          z-index: -1;
        }
      }
    }
  }

  .inner {
    @include padding-right(1);
    @include padding-left(1);

    @include susy-breakpoint($breakpoint-large, $susy-large) {

      @include padding-left(0);
      @include padding-right(0);
    }
  }


  .logo {
      display: block;
      @include rhythm-height(1);
      @include padding-top(1);
      @include susy-breakpoint($breakpoint-large, $susy-large) {
          //@include rhythm-height(1.5);
          height: auto;
          @include padding-top(0);
      }
      img {
          @include margin-top(-.10);
          @include rhythm-height(1.15);
          @include susy-breakpoint($breakpoint-large, $susy-large) {
              @include margin-top(.25);
              @include rhythm-height(3.5);
          }
      }
      @include susy-breakpoint($breakpoint-large, $susy-large) {
          @include span(2);
      }
  }

  @include susy-breakpoint($breakpoint-large, $susy-large) {

  }

}