.block {
  display: block;
  position: relative;

  @include padding-top(.5);
  @include padding-bottom(.5);

  @include margin-top(1.5);

  clear: both;

  &.bg-grey {
    background-color: palette(grey, bg);
    @include padding-top(4);
    @include padding-bottom(3);
  }

  .inner {
    //@include clearfix();
    @include padding-right(1);
    @include padding-left(1);
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      @include padding-right(0);
      @include padding-left(0);
    }

  }

  .footer {
    display: block;
    clear: both;
    text-align: center;
    padding-left: span(1);
    padding-right: span(1);
    @include padding-top(2);
    @include padding-bottom(0);
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      padding-left: span(1);
      padding-right: span(1);
    }
  }

  .intro {
    display: block;
    clear: both;
    text-align: center;
    @include padding-bottom(2);
    h1, h2, h3 {
      position: relative;
      letter-spacing: -1px;
      @include margin-bottom(0);
      //+p {
      //  @include margin-top(-1);
      //}
      &:after {
        content: ' ';
        position: absolute;
        @include line-width(9);
        left: -50%;
        right: -50%;
        bottom: 0;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid palette(grey, bg);
        @at-root .block.bg-grey {
          h1, h2, h3 {
            &:after {
              border: 1px solid palette(white, base);
            }
          }
        }
      }
    }
    //overflow: hidden;
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      @include span(10);
      @include prefix(2);
    }
  }

  &.text_center {
    .content {
      @include padding-top(2);
      @include padding-bottom(2);
      text-align: center;
      p {
        @include rem('font-size', ms(3));
      }
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        margin-left: span(2);
        margin-right: span(2);
        //@include span(10);
        //@include prefix(2);
      }
    }
    &.small {
      .content {
        p {
          @include rem('font-size', ms(1));
        }
      }
    }

  }

  &.text_half {
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      @include span(6);
    }
  }

  &.quote_sameheight-img {
    display: block;
    position: relative;
    .content {
      position: relative;
      overflow: hidden;
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include custom-border-radius(0, 0, 14, 0);
        overflow: hidden;
      }

      .text {

        @include margin-top(8);
        @include rhythm-padding(2);
        @include padding-left(2);
        @include padding-right(2);
        background-color: palette(green, base);
        text-align: center;
        &.grey {
          background-color: palette(grey, bg);
          p, h2, h3, h4 , h5, h6, blockquote {
            color: palette(blue, base);
          }
        }
        @include susy-breakpoint($breakpoint-large, $susy-large) {
          @include span(5 inside no-gutter last);
          @include margin-top(0);
          @include custom-border-radius(0, 0, 14, 0);
          text-align: left;
          overflow: hidden;
          @at-root .block.quote_sameheight-img {
            &.img-left {
              .text {
                //float: left;
                @include span(5 inside no-gutter);
                @include custom-border-radius(0, 0, 0, 0);
              }
            }
          }
        }
        p, h2, h3, h4 , h5, h6, blockquote {
          color: palette(white, base);
        }

        blockquote {
          @include set-font-size-variant(xlarge);
          @include custom-line-height(1.5);
          &:after {
            margin-left: auto;
            margin-right: auto;
            left: -50%;
            right: -50%;
            @include susy-breakpoint($breakpoint-large, $susy-large) {
              left: 0;
              right: auto;
            }
          }
        }

        .person {
          font-family: $font-family-headings;
          font-weight: 800;
          overflow: hidden;
          @include line-height(2);
          @include susy-breakpoint($breakpoint-large, $susy-large) {
            @include line-height(1);
          }
          span {
            font-family: $font-family;
            //font-weight: 100;
            color: palette(blue, base);
          }
          @at-root .block.quote_sameheight-img .content .text.grey {
            .person {
              color: palette(green, base);
            }
            blockquote {
              &:after {
                background-image: url("img/quote_groen.svg");
              }
            }
          }
        }
      }

      .image {
        position: absolute;
        top:0;
        right: 0;
        left: 0;
        @include line-height(8);
        @include susy-breakpoint($breakpoint-large, $susy-large) {
          @include span(7 inside no-gutter);
          height: auto;
          bottom: 0;
          left: 0;
          right: auto;
          @at-root .block.quote_sameheight-img {
            &.img-left {
              .image {
                @include span(7 inside no-gutter last);
                left: auto;
                right: 0;
                img {
                  @include custom-border-radius(0, 0, 14, 0);
                }
              }
            }
          }
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover; // fill image inside specified size whitout pulling out of proportion
          object-position: top center;
        }
      }
    }
  }



  &.quote_fullwidth-img {
    display: block;
    position: relative;
    margin-top: 0;
    max-height: 400px;
    overflow: hidden;
    .inner {
      @include padding-left(0);
      @include padding-right(0);
    }

    .block {
      padding-top: 0;
      margin-top: 0;
    }

    .image {
      position: relative;
      //@include absolute-top(-2);
      //right: 0;
      //@include absolute-bottom(2);
      //left: 0;
      z-index: -1;
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include span(12 inside no-gutter);
        @include absolute-top(0);
        @include absolute-bottom(0);
      }
      img {
        //width: 100%;
        //height: 100%;
        //object-fit: cover; // fill image inside specified size whitout pulling out of proportion
        //object-position: top center;
      }
    }

    &.fullwidth {
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        .image {
          @include padding-bottom(3);
        }
      }
    }

    .content {
      position: relative;
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include custom-border-radius(0, 0, 20, 0);
        @include line-height(16);
        //@include margin-bottom(6);
      }

    }
    &.bg-green {
      .content {
        .text {
          h1, h2, h3, p {
            color: palette(white, base);
          }
          &:after {
            background-color: palette(green);
            border: none;
          }
        }
      }
    }

    .text {
      display: block;
      position: relative;
      background-color: transparent;
      @include rhythm-padding(2);
      @include padding-left(2);
      @include padding-right(2);

      @include margin-left(1);
      @include margin-right(1);

      @include margin-top(4);
      //@include margin-bottom(2);
      text-align: center;
      z-index: 4;
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include margin-top(3);
        @include margin-bottom(0);
        @include span(5 no-gutter last);
        margin-right: span(1 wide);
        @include custom-border-radius(0, 0, 14, 0);
        text-align: left;
        overflow: hidden;
        z-index: 5;
      }
      &:after {
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        @include custom-border-radius(0, 14, 14, 14);
        border: 1px solid palette(grey, stroke);
        background-color: palette(white, base);
        z-index: -1;
      }
      p, h2, h3, h4, h5, h6, blockquote {
        color: palette(blue, base);
      }

      blockquote {
        @include set-font-size-variant(xlarge);
        //line-height: .8125rem;
        @include custom-line-height(1.5);
        &:after {
          background-image: url("img/quote_blauw.svg");
          margin-left: auto;
          margin-right: auto;
          left: -50%;
          right: -50%;
          @include susy-breakpoint($breakpoint-large, $susy-large) {
            left: 0;
            right: auto;
          }
        }
      }

      .person {
        font-family: $font-family-headings;
        font-weight: 800;
        overflow: hidden;
        @include line-height(2);
        @include susy-breakpoint($breakpoint-large, $susy-large) {
          @include line-height(1);
        }
        span {
          font-family: $font-family;
          //font-weight: 100;
          color: palette(blue, base);
        }
      }
    }

    &.text-left {

      .content {

        @include padding-bottom(3);

        .text {
          display: block;
          position: relative;
          background-color: transparent;
          @include rhythm-padding(2);
          @include padding-left(2);
          @include padding-right(2);

          @include margin-left(1);
          @include margin-right(1);

          @include margin-top(4);
          //@include margin-bottom(2);
          text-align: center;
          z-index: 4;
          @include susy-breakpoint($breakpoint-large, $susy-large) {
            @include margin-top(3);
            @include margin-bottom(0);
            @include span(5 no-gutter);
            margin-left: span(1 wide);
            @include custom-border-radius(0, 0, 14, 0);
            text-align: left;
            overflow: hidden;
            z-index: 5;
          }

        }

      }

    }
  }




  &.quote_text_left {
    display: block;
    position: relative;
    .inner {
      @include padding-left(1);
      @include padding-right(1);
      @include padding-bottom(2);
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include padding-left(0);
        @include padding-right(0);
      }
    }
    .block {
      padding-top: 0;
      margin-top: 0;
    }
    .image {
      position: relative;
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include span(12 inside no-gutter);
        @include absolute-top(0);
        @include absolute-bottom(0);
        @include span(6 last);
      }
    }
    .text {
      p, h2, h3, h4, h5, h6, blockquote {
        color: #333333;
      }
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include span(6);
      }
    }
    iframe {
      width: 100%;
      @include line-height(16);
    }
  }

  &.quote_text_rechts {
    display: block;
    position: relative;
    .inner {
      @include padding-left(1);
      @include padding-right(1);
      @include padding-bottom(2);
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include padding-left(0);
        @include padding-right(0);
      }
    }
    .block {
      padding-top: 0;
      margin-top: 0;
    }
    .image {
      position: relative;
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include span(12 inside no-gutter);
        @include absolute-top(0);
        @include absolute-bottom(0);
        @include span(6);
      }
    }
    .text {
      p, h2, h3, h4, h5, h6, blockquote {
        color: #333333;
      }
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include span(6 last);
      }
    }
    iframe {
      width: 100%;
      @include line-height(16);
    }
  }

  &.projects_overview {

    .overview {
      display: block;
      position: relative;
      clear: both;

      .item {
        display: block;
        position: relative;
        @include custom-border-radius(0, 0, 0, 14);
        @include margin-bottom(1);
        overflow: hidden;
        background-color: palette(white,base);
        @include susy-breakpoint($breakpoint-large, $susy-large) {
          @include gallery(4);
        }
        .text {
          display: block;
          position: relative;
          a {
            display: block;
            text-decoration: none;
          }
          .textwrap {
            text-align: center;
            @include padding-left(1);
            @include padding-right(1);
            h2 {
              @include margin-bottom(.5);
            }
            p {
              @include margin-top(.5);
            }
          }
          .image {
            @include line-height(8);
            @include margin-bottom(1);
            overflow: hidden;
            img {
              width: 100%;
              @include line-height(8);
              object-fit: cover; // fill image inside specified size whitout pulling out of proportion
              object-position: top center;
            }
          }
        }
        .extra_info {
          display: none;
          position: absolute;
          top:0;
          right: 0;
          left: 0;
          bottom: 0;
          background-color: palette(green, base);
          overflow: hidden;
          @include padding-top(1.5);
          @include padding-right(1.5);
          @include padding-left(1.5);
          @include padding-bottom(1);
          opacity: 0;
          @include custom-border-radius(0, 0, 0, 14);
          overflow: hidden;
          transition: opacity .2s;
          @include susy-breakpoint($breakpoint-large, $susy-large) {
            display: block;
          }
          @at-root .projects_overview .overview .item:hover .extra_info {
            @include susy-breakpoint($breakpoint-large, $susy-large) {
              transition: opacity .4s;
              opacity: 100;
            }
          }
          p, h2 {
            color: palette(white,base);
          }
          h2 {
            @include margin-bottom(.5);
          }
          p {
            @include margin-top(.5);
          }
          .button {
            @include set-font-size-variant(small);
            @include padding-top(0.25);
            @include padding-right(.5);
            @include padding-bottom(0.25);
            @include padding-left(.5);
            @include margin-right(.5);
            @include margin-bottom(.5);
          }

        }
      }

    }

  }


  &.info_block {
    .content {
      position: relative;
      background-color: palette(grey, bg);
      @include clearfix();
      @include padding-left(2);
      @include padding-right(2);
      @include rhythm-padding(2);

      @include custom-border-radius(14,14,0,14);

      overflow: hidden;
      .text {
        h2 {
          @include set-font-size-variant(xxxlarge);
          //@include custom-line-height(1.5);
          color: palette(blue, base);
          @include margin-bottom(.5);
          @include susy-breakpoint($breakpoint-large, $susy-large) {
            @include custom-line-height(1);
          }
        }
        p {
          //@include set-font-size-variant(large);
          //@include rem('font-size', ms(2));
          @include margin-top(.5);
        }
        @include susy-breakpoint($breakpoint-large, $susy-large) {
          @include span(8);
        }
      }
      .image {
        @include susy-breakpoint($breakpoint-large, $susy-large) {
          position: absolute;
          @include span(4 last);
          top: 0;
          right: 0;
          bottom: 0;
          text-align: right;
          overflow: hidden;
          img {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: contain;
            object-position: bottom right;
          }
        }
      }
    }
  }


  &.info_fullwidth {

    .cover_img {
      position: absolute;
      top:0;
      right: 0;
      bottom: 0;
      left: 0;
      background: no-repeat center center;
      background-size: cover;
      //z-index: -1;
      &:after {
        content: ' ';
        position: absolute;
        top:0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: palette(blue, light);
      }
    }
    .content {
      position: relative;
      text-align: center;
      padding-left: span(1);
      padding-right: span(1);
      @include rhythm-padding(4);
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        padding-left: span(1);
        padding-right: span(1);
      }
      h1,h2,p {
        color: palette(white);
      }
      h1,h2 {
        //@include set-font-size-variant(xxxlarge);
        @include rem('font-size', ms(8));
        @include custom-line-height(1.5);
      }
      p {
        @include rem('font-size', ms(2));
        @include padding-bottom(1);
      }
    }

  }


  &.quote_fullwidth {
    display: block;
    position: relative;
    .content {
      position: relative;
      overflow: hidden;
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include custom-border-radius(0, 0, 14, 0);
        overflow: hidden;
      }

      .text {

        @include margin-top(8);
        @include rhythm-padding(2);
        @include padding-left(2);
        @include padding-right(2);
        background-color: palette(green, base);
        text-align: center;
        @include susy-breakpoint($breakpoint-large, $susy-large) {
          //@include span(5 inside no-gutter last);
          @include margin-top(0);
          @include custom-border-radius(0, 0, 14, 0);
          text-align: left;
          overflow: hidden;
        }

        p, h2, h3, h4 , h5, h6, blockquote {
          color: palette(white, base);
        }

        blockquote {
          @include set-font-size-variant(xlarge);
          @include custom-line-height(1.5);
          &:after {
            margin-left: auto;
            margin-right: auto;
            left: -50%;
            right: -50%;
            @include susy-breakpoint($breakpoint-large, $susy-large) {
              left: 0;
              right: auto;
            }
          }
        }

        .person {
          font-family: $font-family-headings;
          font-weight: 800;
          overflow: hidden;
          text-align: right;
          @include line-height(2);

          @include padding-bottom(0);
          @include margin-top(0);
          @include margin-bottom(0);

          @include susy-breakpoint($breakpoint-large, $susy-large) {
            @include line-height(1);
          }
          span {
            font-family: $font-family;
            //font-weight: 100;
            color: palette(blue, base);
          }
        }
      }

    }
  }






  &.title_block {
    background-color: palette(green,base);
    @include padding-top(3);
    @include padding-bottom(2);

    .content {
      text-align: center;
      h1, p {
        color: palette(white,base);
      }
      h1 {
        @include margin-bottom(0);
      }
      p {
        @include margin-top(0);
      }
    }

  }


  &.article_block {

    .inner {

      .content {

        @include susy-breakpoint($breakpoint-large, $susy-large) {
          margin-left: span(2 wide);
          margin-right: span(2 wide);
        }
      }
    }
  }

  .article {

    a {
      color: palette(green,base);
      text-decoration: none;
      @include susy-breakpoint($breakpoint-large, $susy-large) {
       &:hover {
         text-decoration: underline;
       }
      }
    }

    blockquote {
      position: relative;
      @include padding-left(2.5);
      margin-left: 0;
      margin-right: 0;
      @include margin-top(2);
      @include margin-bottom(2);
      @include padding-top(0);

      @include set-font-size-variant(xlarge);
      @include custom-line-height(1.5);
      color: palette(blue,base);

      &:after {
        content: ' ';
        @include line-width(3);
        @include line-height(1.5);
        position: absolute;
        top: 0;
        left: 0;
        background-image: url("img/quote_groen.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .info_footer {
      text-align: left;
      @include margin-top(2);
      .button {
        color: palette(white,base);
        border-radius: 0;
        @include padding-left(.75);
        @include padding-right(.75);
        font-family: $font-family;
      }

    }

  }


  &.galleryBlock {
    @include padding-top(2);
  }


  &.form_block {

    .content {
      background-color: palette(white,base);
      text-align: center;
      @include padding-top(3);
      @include padding-bottom(1);
      @include padding-left(1);
      @include padding-right(1);
      overflow: hidden;

      box-shadow: 0px 0px 50px 7px palette(grey, bgwidgetdate);

      h1,h2,h3 {
        color: palette(blue,base);
        @include rem('font-size', ms(3));
        margin-bottom: 0;
      }
      p.medium {
        @include rem('font-size', ms(1));
        margin-top: 0;
      }
      form {
        text-align: left;
      }
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        margin-left: span(2);
        margin-right: span(2);
        @include padding-left(0);
        @include padding-right(0);
        .form {
          margin-left: span(2);
          margin-right: span(2);
        }
      }
    }


  }



}