/* =============================================
   Grid setup. Also see: http://susy.oddbird.net/demos/grid-types/
   ============================================= */
$susy-debug: (
        debug:(
                image: show-columns,
                color:rgba(0, 128, 0, .2),
                output: background,
        )
);

$susy: (
        columns: 6,
        gutters: 1/8,
        math: fluid,
        output: float,
        box-sizing: border-box,
);

$susy-large: (
        columns: 12,
        gutters: 1/3,
        math: fluid,
        output: float,
        // note the 18px -> this is the base font-size for the large breakpoint. @see $base-font-sizes in typography.scss
        column-width: (66px / 16px) *1em,
        box-sizing: border-box,

        //// for 1140px grid
        //flow: ltr,
        //output: float,
        //math: fluid,
        //// column-width: false,
        //container: 1140px,
        ////container-position: left,
        //last-flow: to,
        //columns: 12,
        //gutters: 1/3,
        //gutter-position: after,
        //global-box-sizing: border-box,
);

@if ($debug) {
  $susy: map-merge($susy, $susy-debug);
  $susy-large: map-merge($susy-large, $susy-debug);
}

// Grid container setup
.wrapper, .inner {
  // make it the container of the grid
  @include container;
  @include susy-breakpoint($breakpoint-large, $susy-large) {
    @include container();
  }
}
