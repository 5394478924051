footer {
  display: block;
  position: relative;
  @include margin-top(1.5);
  clear: both;

  section {
    color: palette(white);
    text-align: left;
    @include rem('font-size', ms(-1));
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      @include span(3);
      &.last {
        @include span(6 last);
      }
    }

  }

  .footer_logo {
    display: inline-block;
    margin: 0;
    padding: 0;
    @include line-height(3);
    width: auto;
  }

  .block {

    background-color: palette(grey, bg);

    .inner {
      @include rhythm-padding(3);
      h3:first-of-type {
        color: palette(green, base);
        @include margin-bottom(2);
      }

      .item {
        &.introtext {
          p:first-of-type {
            @include susy-breakpoint($breakpoint-large, $susy-large) {
              @include padding-top(2);
            }
          }
        }
        .nieuwsbox {
          h3 {
            @include margin-bottom(.5);
          }
        }
      }

    }
  }

  .copyright {
    display: block;
    color:  palette(white, base);
    @include rhythm-padding(1);

  }

  p {
    color: palette(white, base);
  }

}


.footer_social_icons {
  @include margin-top(0);
  a {
    display: inline-block;
    @include line-height(1);
    @include line-width(1);
    img {
      max-width: 100%;
      height: auto;
      @include line-height(1);
      @include line-width(1);
    }
  }
}