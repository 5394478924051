.button, input[type=submit] {
  position: relative;
  font-family: $font-family-headings;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  //@include rem('font-size', ms(-1));
  background-color: palette(blue, base);
  color: palette(white);
  @include padding-top(0.25);
  @include padding-right(1.5);
  @include padding-bottom(0.25);
  @include padding-left(1.5);
  @include margin-right(.5);
  @include margin-bottom(.5);
  text-decoration: none;
  border: 0;
  border-radius: 4px;
  outline: none;

  &:after {
    border-radius: 4px;
  }
  @include susy-breakpoint($breakpoint-large, $susy-large) {
    @include padding-top(0.5);
    @include padding-right(1.5);
    @include padding-bottom(0.5);
    @include padding-left(1.5);
    &:hover {
      background-color: palette(blue, light);
    }
  }

  &.green {
    background-color: palette(green, base);
    color: palette(white);
    @include susy-breakpoint($breakpoint-large, $susy-large) {

      &:hover {
        background-color: palette(green, light);
        color: palette(white);
      }
    }
  }

  &.green_trans {
    background-color: transparent;
    color: palette(green, base);
    &:after {
      content: ' ';
      position: absolute;
      top:0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid palette(green, base);
    }
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      &:hover {
        background-color: transparent;
        color: palette(green, light);
        &:after {
          border: 1px solid palette(green, light);
        }
      }
    }
  }

  &.blue_trans {
    background-color: transparent;
    color: palette(blue, base);
    &:after {
      content: ' ';
      position: absolute;
      top:0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid palette(blue, base);
    }
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      &:hover {
        background-color: transparent;
        color: palette(blue, light);
        &:after {
          border: 1px solid palette(blue, light);
        }
      }
    }
  }

  &.white_trans {
    background-color: transparent;
    color: palette(white, base);
    &:after {
      content: ' ';
      position: absolute;
      top:0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid palette(white, base);
    }
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      &:hover {
        background-color: transparent;
        color: palette(white, light);
        &:after {
          border: 1px solid palette(white, light);
        }
      }
    }
  }

}

.leesmeer {
  display: block;
  font-family: $font-family-headings;
  text-decoration: none;
  color: palette(blue, base);

  @include susy-breakpoint($breakpoint-large, $susy-large) {
    &:hover {
      text-decoration: underline;
    }
  }
}

.leesmeer, .button, input[type=submit] {
  i {
    font-size: 1.2em;
    vertical-align: middle;
    margin-left: 4px;
  }
}