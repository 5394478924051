// Navigation
nav {
    position: relative;
    font-family: $font-family-headings;
    ul {
        padding: 0;
        margin: 0;
    }
    li {
        padding: 0;
        margin: 0;
        display: block;
        &:before {
            content: '';
        }
    }
    a {
        font-weight: 200;
        color: palette(grey, text);
    }
}

.nav__item {
    //@include set-font-size-variant('small');
    width: 100%;
    a {
        color: palette(grey, text);
    }
}

.nav__main {
    display: block;
    position: fixed;
    top:0;
    right: 0;
    bottom: 0;

    margin-right: -100%;

    //@include span(5 inside no-gutter);
    width: 100%;
    @include padding-left(3.5);
    @include padding-right(1);
    @include padding-top(1);
    @include padding-bottom(1);

    z-index: 10;
    overflow-y: scroll;

    transition: margin-right .5s;

    &:before {
        transition: margin-right .5s;
        display: block;
        content: ' ';
        position: fixed;
        top:0;
        right: 0;
        bottom: 0;
        margin-right: -100%;
        //@include span(5 inside no-gutter);
        width: 100%;
        @include padding-left(3);

        background-color: palette(grey, bg);
        @at-root .nav__main {
            &.is-active {
                &:before {
                    @include margin-right(-3);
                }
            }
        }
    }

    @include susy-breakpoint($breakpoint-large, $susy-large) {

        @include padding-top(1.5);
        @include padding-bottom(1.5);
        @include padding-left(0);
        @include padding-right(0);

        display: block;
        position: relative;
        @include span(last 10);
        text-align: right;
        background-color: transparent;
        overflow-y: visible;
        transition: none;
        &:before {
            content: normal;
        }

    }

    &.is-active {
        display: block;
        margin-right: 0;
    }

    ul > li {
        display: block;
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            display: inline-block;
        }
        a {
            position: relative;
            color: palette(white, base);
            height: auto;
            border-radius: 3px;
            @include padding-top(0.5);
            @include padding-right(0);
            @include padding-left(0.5);
            @include padding-bottom(0.5);
            @include margin-left(1);
            @include margin-right(.25);

            @include susy-breakpoint($breakpoint-large, $susy-large) {
                color: palette(white, base);
                @include rhythm-height(1.5);
                border-radius: 3px;
                @include padding-top(0.25);
                @include padding-left(0);
                @include padding-right(0);
                @include padding-bottom(0.25);
                @include margin-left(.25);
            }

            &:after {
                content: ' ';
                position: absolute;
                @include absolute-right(.5);
                bottom: 0;
                @include absolute-left(.5);
                border-bottom: 1px solid palette(grey, light);
            }
            @include susy-breakpoint($breakpoint-large, $susy-large) {
                color: palette(grey, text);
                &:after {
                    content: normal;
                }
            }
        }

        &.menu-item-has-children {

            > a {
                position: relative;
                @include susy-breakpoint($breakpoint-large, $susy-large) {
                    @include padding-right(.4);
                    @include margin-right(.5);
                }
                &:before {
                    content: '\f107';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: auto;
                    margin-top: auto;
                    margin-bottom: auto;
                    @include margin-right(.5);
                    font-family: FontAwesome;
                    @include line-width(.5);
                    @include line-height(1);
                    color: palette(white,base);
                    text-align: right;
                    @include custom-line-height(1);
                    vertical-align: middle;
                    font-size: 1rem;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    text-decoration: none;
                    border: none;
                    @include susy-breakpoint($breakpoint-large, $susy-large) {
                        color: palette(grey, text);
                        @include margin-right(-.25);
                    }
                    @at-root .faq_block ul.faqs li.question.showanswer .question_wrap {
                        &:after {
                            content: '\f106';
                        }
                    }
                }
            }


        }

    }
    a {
        color: palette(grey, text);
        text-decoration: none;
        font-weight: 300;
        display: block;
        vertical-align: middle;
        @include set-font-size-variant(small);
        &:hover {
            @include susy-breakpoint($breakpoint-large, $susy-large) {
                color: palette(green, base);
            }
        }
        &.current-menu-item {

        }
        @at-root .highlight a {
            background-color: palette(blue, light);
            color: palette(white, base);
        }
    }
    ul > li {

        &.tapped {
            ul {
                display: block;
                @include susy-breakpoint($breakpoint-large, $susy-large) {
                    display: none;
                }
            }
        }

        > ul {
            position: relative;
            display: none;

            @include susy-breakpoint($breakpoint-large, $susy-large) {
                position: absolute;
                @include absolute-top(1.25);
                @include absolute-left(-1.5);
                @include padding-left(1);
                @include padding-right(1);
                text-align: left;
                width: 18em;

                @include padding-top(1);
                @at-root .nav__main ul li:hover
                ul {
                    display: block;
                }

                &:after {
                    content: ' ';
                    position: absolute;
                    @include absolute-top(1);
                    left: 0;
                    right: 0;
                    border-top: 2px solid palette(green, base);
                }
                &:before {
                    content: ' ';
                    position: absolute;
                    @include absolute-top(1);
                    right: 0;
                    bottom: 0;
                    left: 0;
                    border-top: 2px solid palette(green, base);
                    background-color: palette(white);
                    @include custom-border-radius(0, 0, 10, 0);
                }
            }
        }
        li {
            display: block;
            a {
                position: relative;
                @include padding-left(1);
                font-family: $font-family;
                @include susy-breakpoint($breakpoint-large, $susy-large) {
                    margin: 0;
                    padding: 0;
                    height: auto;
                    @include rhythm-padding(1);
                    @include padding-left(1.5);
                    @include padding-right(1.5);
                    &:after {
                        content: ' ';
                        position: absolute;
                        @include absolute-right(1);
                        bottom: 0;
                        @include absolute-left(1);
                        border-bottom: 1px solid palette(grey);
                    }
                }
            }
        }
    }
}

.open_nav, .close_nav {
    @include flexEmbed(1, 1);
    position: fixed;
    top: 0;
    @include line-width(3);
    background-color: palette(white, base);
    text-align: center;

    span {
        position: absolute;
        top:0;
        right: 0;
        left: 0;
        bottom:0;
        white-space: nowrap;
        text-indent: 200%;
        overflow: hidden;
        cursor: pointer;

    }

    i {
        display: block;
        position: absolute;
        top:0;
        right: 0;
        left: 0;
        bottom:0;
        @include margin-top(.75);
        z-index: -1;
    }

}

.open_nav {
    display: block;
    z-index: 10;
    right: 0;

    margin-top: 0;
    transition: margin-right .8s;

    @include susy-breakpoint($breakpoint-large, $susy-large) {
        display: none;
    }

    @at-root body {
        &.nav-active {
            .open_nav {
                margin-right: -100%;
            }
        }
    }
}


.close_nav {
    margin-top: -100%;
    transition: margin-top .8s;
    z-index: 11;
    @at-root body {
        &.nav-active {
            .close_nav {
                margin-top: 0;
                @include susy-breakpoint($breakpoint-large, $susy-large) {}
            }
        }
    }
    @include susy-breakpoint($breakpoint-large, $susy-large) {
        display: none;
    }

}

.nav__footer {

    @include margin-top(0);

    li {
        display: block;
        a {
            @include rem('font-size', ms(-1));
            display: block;
            position: relative;
            font-family: $font-family;
            text-decoration: none;
            margin: 0;
            padding: 0;
            height: auto;
            color: palette(white,base);
            //@include rhythm-padding(.25);
            @include padding-bottom(0);

            &:after {
                content: ' ';
                position: absolute;
                @include absolute-right(0);
                bottom: 0;
                @include absolute-left(0);
                //border-bottom: 1px solid palette(grey, navborders);
            }

        }
        &:last-of-type {
            a {
                &:after {
                    content: normal;
                }
            }
        }
        ul {
            display: none;
        }
    }

}