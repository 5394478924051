//$mob: new-breakpoint(max-width 760px);
//$one: new-breakpoint(min-width 321px);
//$two: new-breakpoint(min-width 441px);
//$three: new-breakpoint(min-width 761px);
//$four: new-breakpoint(min-width 1285px);

/* ---- grid-item ---- */

.grid-item {
  //width: 100px;
  height: auto;
  float: left;
  //@include span(2);
  //@include gallery(2);
  margin-bottom: 25px;

  background-color: palette(grey,bg);

  @include susy-breakpoint($breakpoint-large, $susy-large) {
    //@include span(4);
    //@include gallery(4);
    width: 320px;
    //width: span(4);
  }
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

}

/*
 * 	Core pix Carousel CSS File
 *	v1.3.3
 */

/* clearfix */

.pix-carousel {
  .pix-wrapper {
    &:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0;
    }
    display: none;
    position: relative;
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  display: none;
  position: relative;
  width: 100%;
  -ms-touch-action: pan-y;
  .pix-wrapper-outer {
    overflow: hidden;
    position: relative;
    width: 100%;
    &.autoHeight {
      -webkit-transition: height 500ms ease-in-out;
      -moz-transition: height 500ms ease-in-out;
      -ms-transition: height 500ms ease-in-out;
      -o-transition: height 500ms ease-in-out;
      transition: height 500ms ease-in-out;
    }
  }
  .pix-item {
    float: left;
  }
}

/* display none until init */

.pix-controls {
  .pix-page, .pix-buttons div {
    cursor: pointer;
  }
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* mouse grab icon */

.grabbing {
  cursor: url(grabbing.png) 8 8, move;
}

/* fix */

.pix-carousel {
  .pix-wrapper, .pix-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
  }
}



/*
* 	pix Carousel
*/

.pix-theme .pix-controls {
  margin-top: 10px;
  text-align: center;
  .pix-buttons div {
    color: #FFF;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    zoom: 1;
    //*display: inline;
    /*IE7 life-saver */
    padding: 3px 10px;
    font-size: 12px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background: #869791;
    filter: Alpha(Opacity = 50);
    /*IE7 fix*/
    opacity: 0.5;
    &.pix-prev {
      left: 0;
    }
    &.pix-next {
      right: 0;
    }
  }
  &.clickable .pix-buttons div:hover {
    filter: Alpha(Opacity = 100);
    /*IE7 fix*/
    opacity: 1;
    text-decoration: none;
  }
  .pix-page {
    display: inline-block;
    zoom: 1;
    *display: inline;
    /*IE7 life-saver */
    span {
      display: block;
      width: 12px;
      height: 12px;
      margin: 5px 7px;
      filter: Alpha(Opacity = 50);
      /*IE7 fix*/
      opacity: 0.5;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
      background: #869791;
    }
    &.active span {
      filter: Alpha(Opacity = 100);
      /*IE7 fix*/
      opacity: 1;
    }
  }
  &.clickable .pix-page:hover span {
    filter: Alpha(Opacity = 100);
    /*IE7 fix*/
    opacity: 1;
  }
  .pix-page span.pix-numbers {
    height: auto;
    width: auto;
    color: #FFF;
    padding: 2px 10px;
    font-size: 12px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
  }
}

/* Styling Next and Prev buttons */

/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */

/* Styling Pagination*/

/* If PaginationNumbers is true */

/* preloading images */

.pix-item.loading {
  min-height: 150px;
  background: url(AjaxLoader.gif) no-repeat center center;
}



.photogalery {
  display: none;
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  bottom:0;
  z-index: 30;
  background-color: palette(white,base);
  &.slide-active {
    display: block;
  }
  .sliderSluiten {
    display: block;
    position: fixed;
    top:0;
    right: 0;
    @include line-height(2);
    @include line-width(2);
    @include margin-top(.5);
    @include margin-right(.5);
  }
  //.slider_nav {
  //  position: fixed;
  //  top: 0;
  //  z-index: 40;
  //}
  nav {
    a {
      display: block;
      position: fixed;
      top: 50%;
      margin-top: -10px;
      bottom: -38px;
      padding: 3px 10px;
      font-size: 12px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
      &:hover {
        //@include media($three) {
        //  opacity: 1;
        //}
      }
      //@include media($three) {
      //  top: 50%;
      //  bottom:auto;
      //  transform: translateY(-50%);
      //  zoom: 1;
      //  filter: Alpha(Opacity = 50);
      //  /*IE7 fix*/
      //  opacity: 0.5;
      //  @include transition(opacity 0.2s ease 0s);
      //}
      &.prevpix, &.nextpix {
        cursor: pointer;
        img {
          display: block;
          width: 25px;
          height: 25px;
          //@include media($three) {
          //  width: 80px;
          //  height: 80px;
          //}
        }
        span {
          display: none;
          //@include transition(all 0.2s ease 0s);
          //@include media($three) {
          //  display: block;
          //  width: auto;
          //  height: 80px;
          //  font-size: 20px;
          //  line-height: 23px;
          //  text-transform: uppercase;
          //  padding-top: 16px;
          //  opacity: 0;
          //  &.show {
          //    opacity: 1;
          //  }
          //
          //}
        }
      }
      &.prevpix {
        left: 3%;
        text-align: left;
        //@include media($three) {
        //  left: 0;
        //}
        img {
          float: left;
          -ms-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
        span {
          float: left;
          padding-left: 20px;
        }
        &:hover span {
          padding-left: 30px;
        }
      }
      &.nextpix {
        text-align: right;
        right: 3%;
        //@include media($three) {
        //  right: 0;
        //}
        img {
          float: right;
        }
        span {
          float: right;
          padding-right: 20px;
          text-align: right;
        }
        &:hover span {
          padding-right: 30px;
        }
      }
    }
  }
}

.photowrap, #pix-work, .pix-wrapper-outer, .pix-wrapper, .pix-item {
  height: 100%;
}
.photowrap {
  text-align: center;
  img {
    position: relative;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    //-webkit-transform: translateY(-50%);
  }
}
.photo {
  text-align: center;
}


