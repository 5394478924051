input {
  outline: none;
  border: none;
  @include rhythm-height(2);
  @include padding-left(1);
  @include padding-right(.5);
  @include margin-bottom(.5);
  @include rem('font-size', ms(-1));
  border: 1px solid palette(grey,bg);
  box-sizing: border-box;

  margin: -1px;
  &:focus {
      outline: none;
  }
  &[type=text], &[type=email], &[type=search] {
      display: block;
      width: 100%;
      box-sizing: border-box;
  }
  &[type=submit] {
      //height: auto;
  }
  &.wpcf7-not-valid {
    border: 1px solid red;
  }
}

span[role=alert], .wpcf7-not-valid-tip {
  display: none;
}

.wpcf7 {
  .screen-reader-response {
    display: none;
  }
  .ajax-loader {
    position: absolute;
    @include line-height(.5);
    @include line-width(.5);
    bottom: 0;
    left: 0;
  }
}

.wpcf7-list-item {
  input[type=radio], input[type=checkbox], span.wpcf7-list-item-label {
    @include line-height(1);
    @include custom-line-height(1);
    display: inline-block;
    vertical-align: text-top;
    @include margin-bottom(1);
  }
  input[type=radio], input[type=checkbox] {
    @include margin-right(.125);
  }
  span.wpcf7-list-item-label {
    @include margin-right(.5);
    @include rem('font-size', ms(-1));
  }
}

.wpcf7-checkbox {
  .wpcf7-list-item {
    display: block;
    input[type=checkbox], span.wpcf7-list-item-label {
      @include margin-bottom(0);
    }
  }
}

textarea {
  display: block;
  width: 100%;
  outline: none;
  border: none;
  @include padding-top(.5);
  @include padding-bottom(.5);
  @include padding-left(1);
  @include padding-right(1);
  @include margin-bottom(.5);
  @include rem('font-size', ms(-1));
  border: 1px solid palette(grey,bg);
  box-sizing: border-box;
  margin: -1px;
  max-width: 100%;
  &:focus {
    outline: none;
  }
}

.input--text, .input--text:valid {
  @include rem('font-size', ms(-1));

  display: block;
  margin: 0;
  border: none;
  border-radius: 0;
}

.input--error, .input--text:invalid {
  border: 1px solid red;
}



